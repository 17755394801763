function Header() {

  return (
    <>
      <div className="position-relative">
        <div className="row">
          <img className="im-height" src="img/1.jpg" alt="Innovatica Labs"/>
        </div> 
        {/* <button class="btn btn-lg btn-primary position-absolute top-50 start-60 translate-middle">For Clients</button>
        <button class="btn btn-lg btn-primary position-absolute top-50 start-40 translate-middle">Job Seekers</button> */}

      </div>
    </>
    
  );
}

export default Header;

function About() {
  return (
    <>
      <div
        style={{ marginTop: "0.5rem", width: "100%", height: "10px" }}
        className="about-scroll"
      ></div>
      <h2 id="about" className="text-center headingTxtColor mb-30">About Us</h2>
      <hr></hr>
      <div className="container about">
        <div className="row">
          <div className="col-md-6">
            <div id="who-we-are">
              <h2 className="text-center headingTxtColor">Who We Are</h2>
              <p>
                Innovatica Labs is a IT consulting firm committed to bridging
                the gap between technology and business challenges. With decades
                of combined experience, our team has been at the forefront of
                innovating and integrating IT solutions that matter. From the
                bustling hubs of Silicon Valley to international tech
                landscapes, our footprint is global, but our focus is singular:{" "}
                <em>To help businesses thrive in a digital age</em>.
              </p>
            </div>
            </div>
            <div className="col-md-6">

            <div id="our-mission">
              <h2 className="text-center headingTxtColor">Our Mission</h2>
              <p>
                In today’s rapidly evolving digital landscape, our mission is to
                empower businesses with cutting-edge IT solutions that drive
                growth, enhance efficiency, and secure their digital assets. We
                envision a world where businesses, irrespective of their size or
                industry, have effortless access to the best IT minds and
                solutions, and we're here to make that happen.
              </p>
            </div>
            </div>
            <div className="row">
            <div className="col-md-6">
            <div id="our-expertise">
              <h2 className="text-center headingTxtColor">Our Expertise</h2>
              <ul>
                <li>
                  <strong>Digital Transformation</strong>: Helping businesses
                  make the digital shift with strategies that encompass emerging
                  technologies and the latest industry trends.
                </li>
                <li>
                  <strong>Cloud Services</strong>: Assisting in migrating,
                  deploying, and managing applications in cloud environments
                  with a focus on scalability and security.
                </li>
                <li>
                  <strong>Cybersecurity</strong>: Providing top-notch protection
                  strategies and solutions against the ever-evolving digital
                  threats of the modern world.
                </li>
                <li>
                  <strong>Software Development</strong>: Crafting bespoke
                  software solutions tailored to unique business needs, ensuring
                  scalability and robustness.
                </li>
                <li>
                  <strong>Data Analytics & AI</strong>: Turning data into
                  actionable insights with the power of advanced analytics and
                  artificial intelligence.
                </li>
              </ul>
            </div>
            </div>
            <div className="col-md-6">
            <div id="our-values">
              <h2 className="text-center headingTxtColor">Our Values</h2>
              <ul>
                <li>
                  <strong>Client-Centric Approach</strong>: Our clients' success
                  is our success. We prioritize their needs and tailor solutions
                  that align with their goals.
                </li>
                <li>
                  <strong>Innovation</strong>: In a rapidly evolving IT
                  landscape, we constantly push the boundaries to integrate the
                  latest and most effective solutions for our clients.
                </li>
                <li>
                  <strong>Ethics and Integrity</strong>: We believe in doing
                  business right, emphasizing transparency, honesty, and a
                  strong moral code in all our dealings.
                </li>
                <li>
                  <strong>Collaboration</strong>: Our global team of experts
                  collaborates seamlessly to bring together a wealth of
                  knowledge, ensuring optimal solutions for every challenge.
                </li>
              </ul>
            </div>
            </div>
            </div>
          
            <div id="join-us" className="row">
              <h2 className="text-center headingTxtColor">Join Us on the Road to the Future</h2>
              <p>
                At Innovatica Labs, we’re not just about the technology; we’re
                about the stories it writes, the problems it solves, and the
                people it helps. As we look ahead, our compass is set on
                innovations that matter and solutions that make a difference.
                Whether you're a business looking for an IT partner or a
                professional eager to make an impact, there's a place for you
                here.
              </p>
            </div>
          </div>
        </div>
    </>
  );
}
export default About;

function Card(props) {
  return (

<div class="card">
  <img class="card-img-top" src={`/img/${props.img}`} alt="Card image cap"/>
  <div class="card-body">
    <h5 class="card-title">{props.title}</h5>
    <p class="card-text">{props.text}</p>
  </div>
</div>

  );
}
export default Card;

import { Link, animateScroll as scroll } from 'react-scroll'

function Footer() {


  return (
    <footer>
      <div className="container">
        <div className="row">
              <div className="col-md-3">
                <h1 className="logo">Innovaticalabs</h1>
                <p className="footer-text">
                    We are a budding company, passionately working each day to make a positive impact on the world.
                </p>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Contact</p>
                <ul>
                  <li className="footer-text">
                    hr@innovaticalabs.com
                  </li>
                  <li className="footer-text">
                    318 Georgia Lace Trl, Georgetown, TX, 78626
                  </li>
                  <li className="footer-text">
                    <p>+1 (616)-469-7738</p>
                  </li>
                </ul>
              </div>
              <div className="offset-3 col-md-3">
                <p className="footer-title">Social Media</p>
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/burhan-kocada%C4%9F-49a3331a5/"> Linkedin</a>
                  </li>
                </ul>
              </div>
        
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop"><i className="fas fa-level-up-alt"></i></button>

    </footer>
  );
}
export default Footer;

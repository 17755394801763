import Card from "./Card";
function Services() {
  return (
    <div id="services" className="container services">
        <h2 className="mb10 text-center headingTxtColor">SERVICES</h2>
        <hr></hr>
        <div class="row row-cols-1 row-cols-md-3 g-4">
            <Card title="Application Development" img="app-development.jpg" text="At Innovatica Labs, we specialize in crafting cutting-edge applications that drive innovation and elevate businesses to new horizons. Our experienced team of developers, architects, and designers collaborate seamlessly to transform your ideas into intuitive, robust, and scalable applications " />
            <Card title="DevOps" img="devops.jpg" text="At Innovatica Labs, we believe DevOps is a journey, not a destination. Our holistic approach goes beyond mere toolchains, focusing on cultivating a culture of collaboration, continuous learning, and shared responsibility." />
            <Card title="Project Management" img="project management.jpg" text="At Innovatica Labs, we don’t just manage tasks; we strategize success. Our project management approach is rooted in understanding the broader business landscape and aligning project goals with organizational objectives." />
        </div>
        <div class="row mt10 row-cols-1 row-cols-md-3 g-4 mt5">
            <Card title="Quality Assurance" img="quality-assurance.jpg" text="At Innovatica Labs, Quality is not just priority but it is our pride. We make it possible with our state-of-the-art Quality Assurance Testing Services that is available to our in-house projects and to our clients. " />
            <Card title="Network and Security" img="network-and-security.jpg" text="At Innovatica Labs, we believe DevOps is a journey, not a destination. Our holistic approach goes beyond mere toolchains, focusing on cultivating a culture of collaboration, continuous learning, and shared responsibility." />
            <Card title="Cloud Infrastructure" img="cloud-computing.jpg" text="At Innovatica Labs, our approach to cloud migration is rooted in a deep understanding of your business needs. We ensure a seamless and strategic transition that minimizes disruptions and maximizes operational continuity." />
        </div>
    </div>
  );
}
export default Services;
